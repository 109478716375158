/*** IMPORTS FROM imports-loader ***/
var define = false;

import gsap from 'gsap'

const Contact = () => {

    return {
        namespace: 'contact',

        beforeLeave() {
        },
        afterLeave() {
        },
        beforeEnter(data) {

            /*
            |
            | Constants
            |------------
            */
            const
            $window = $(window),
            $map = $(data.next.container).find('#map'),
            $lazyContainer = $map.parent()
            ;

            /*
            |
            | Init Google Map
            |----------------
            |
            */
            $('body').on('mapInview', () => {
                
                $lazyContainer.addClass('is-load')
                
                if (typeof window.createMap === 'function') {
                    window.createMap()
                } else {
                    window.createMap = () => {
                        let map = new google.maps.Map(document.getElementById("map"), {
                            center: { lat: -34.397, lng: 150.644 },
                            zoom: 8,
                        })
                    }
                    // load the Google Map API script
                    let script = document.createElement('script')
                    script.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyDGF98psyVSiyiVhFovqfhMDrGJ-Ls2Uoc&callback=createMap'
                    data.next.container.appendChild(script)
                }
            })
        },
        afterEnter(data) {
        }
    }
}

export default Contact

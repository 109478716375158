/*** IMPORTS FROM imports-loader ***/
var define = false;

import gsap from 'gsap'
import 'gsap/TextPlugin'
function enterhome() {
  var en = gsap.timeline();
  en.to('.gallery', 0.8, {
    opacity: 1
  }, 0)
  en.to('.sous-gallery', 0.8, {
    opacity: 1
  }, 0)
  document.querySelector("#toggle").addEventListener("click", animateIt);
  const tl = gsap.timeline();
  tl.to(".gallery", {
    opacity: 0.05,
    ease: "power3.inOut"
  }, 0);
  tl.to("#toggle", {
    text: "Bureau Pleiades",
    ease: "power3.inOut"
  }, 0);
  tl.to("#target", {

    autoAlpha:1,
    ease: "power3.inOut"
  }, 0);
  tl.reversed(true);
  function animateIt() {
    tl.reversed(!tl.reversed());
  }

}




const Home = () => {

  return {
    namespace: 'template-cms',

    beforeLeave() {

    },
    afterLeave() {

    },
    beforeEnter() {

    },
    afterEnter() {
      enterhome()
    }
  }
}

export default Home

/*** IMPORTS FROM imports-loader ***/
var define = false;



const About = () => {

    return {
        namespace: 'about',

        beforeLeave() {
            console.log('leaving about')
        },
        afterLeave() {
            console.log('leaved about')
        },
        beforeEnter() {
            console.log('entering about')
        },
        afterEnter() {
  
        }
    }
}

export default About

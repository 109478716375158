/*** IMPORTS FROM imports-loader ***/
var define = false;

const Projects = () => {

    return {
        namespace: 'archive-projects',

        beforeLeave() { },
        afterLeave() { },
        beforeEnter() {
        },
        afterEnter(data) {

            /*
            |
            | Constants
            |------------
            */
            const
                $body = $('body'),
                $loadMoreBtn = $(data.next.container).find('.load-more-btn'),
                $postsContainer = $(data.next.container).find('.loaded-posts'),
                $loadMoreContainer = $(data.next.container).find('.load-more-container'),
                $loadMore = $(data.next.container).find('.load-more'),
                $loadingContainer = $(data.next.container).find('.loading-container'),
                $total = $(data.next.container).find('.card-article').length,
                lang = $body.data('lang'),
                $limit = 9
                ;

            let xhr = null;

            if ($total < $limit) {
                $loadMoreContainer.hide()
            }
            /*
            |
            | Load more ajax
            |--------------
            */
            $loadMoreBtn.on('click', function (e) {
                console.log('is clicked')
                abort(xhr)

                let offset = $postsContainer.find('.card-article').length
                let url = `/ajax/projects/${offset}/${lang}`

                xhr = $.ajax({
                    url: url,
                    type: 'GET',
                    method: 'GET',
                    dataType: 'json',
                    beforeSend: () => {
                        $loadMore.hide()
                        $loadingContainer.show()
                    },
                    success: (response, status) => {

                        $postsContainer.append(response);
                        $loadingContainer.hide()

                        if (!$postsContainer.find('.no-more-post').length) {
                            $loadMoreContainer.show()
                            $loadMore.show()
                        } else {
                            $loadMoreContainer.hide()
                        }

                        xhr = null
                    },
                    complete: function (response) {

                        setTimeout(() => {
                            window.lazy.update()
                            window.scroll.update()
                        }, 400)

                    },
                    error: (response, status, error) => {
                        console.log(error)
                    }
                });

            });

            /*
            | HELPER
            |
            | abort ajax request
            |------------
            */
            function abort(xhrVar, $list = null) {
                if (xhrVar !== null) {
                    xhrVar.abort()
                }

                if ($list !== null) {
                    $list.find('.loading--ajax').hide()
                }
            }

        }
    }
}

export default Projects

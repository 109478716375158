/*** IMPORTS FROM imports-loader ***/
var define = false;

import * as app from '@components/global.js'
import gsap from 'gsap'
import LocomotiveScroll from 'locomotive-scroll'
import LazyLoad from 'vanilla-lazyload'
import CookieManager from '@components/cookies'



const Main = () => {

    /*
    |
    | Constants
    |-----------
    */
    const
        $window = $(window),
        $body = $('body'),
        $preloader = $('#preloader'),
        $cookieBanner = $('.cookie-banner'),
        $cookieAccept = $cookieBanner.find('.item-accept'),
        $cookieRefuse = $cookieBanner.find('.item-refuse'),
        $burger = $('.burger'),
        $smoothContainer = document.querySelector('[data-scroll-container]'),
        $themeElement = $('[data-theme]')
        ;


    /*
    |
    | Loader
    |---------
    */
    $themeElement.attr('data-theme', (sessionStorage.theme))


    /*
    |
    | Preloader
    |-----------------
    */
   

    /*
    |
    | Locomotive Scroll
    |------------------
    */
    window.scroll = new LocomotiveScroll({
        el: $smoothContainer,
        smooth: true,
        getDirection: true
    })

    window.scroll.on('call', () => {
        app.dispachEvent($body, 'mapInview')
    })


    /*
    |
    | LazyLoad
    |-----------------
    */
    function lazyParent(el) {
        const lazyContainer = el.closest(".lazy-container")
        lazyContainer.classList.add("is-load")
    }

    window.lazy = new LazyLoad({
        unobserve_entered: true,
        callback_loaded: lazyParent
    })


    /*
    |
    | Burger Menu
    |-----------------
    */
    $burger.on('click', function () {
        $body.toggleClass('is-menu')
    })

    function checkDevice() {
        if ("matchMedia" in window) {
            if (window.matchMedia("(min-width:48rem)").matches) {
                $body.removeClass('is-menu')
            }
        }
    }
    window.addEventListener('resize', checkDevice, false);


    /*
    |
    | Header on Scroll
    |
    */

 


  

    /*
    |
    | End
    |-----------------
    */
}
export default Main

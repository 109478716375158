/*** IMPORTS FROM imports-loader ***/
var define = false;

import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'

const Article = () => {

    return {
        namespace: 'single-article',

        beforeLeave() {

        },
        afterLeave() {

        },
        beforeEnter() {

        },
        afterEnter(data) {

            /*
            |
            | Constants
            |------------
            */
            
            /*
            |
            | Progress bar
            |-------------
            */
            

            /*
            |
            | Share container
            |------------
            */
            

        }
    }
}

export default Article

/*** IMPORTS FROM imports-loader ***/
var define = false;

/*
|
| Importing Libs 
|------------------
*/
import gsap from 'gsap'
import barba from '@barba/core'


/*
|
| Importing Components
|-----------------------
*/


/*
|
| Importing Utils
|-------------------
*/


/*
|
| Importing App files
|----------------------
*/
import Main from '@pages/main'

import Home from '@pages/home'
import About from '@pages/about'
import Contact from '@pages/contact'
import News from '@pages/archive-news'
import Article from '@pages/single-news'
import Projects from '@pages/archive-projects'

/*
|
| Global 
|----------
*/
Main()

/*
|
| Constants
|-----------
*/
const
    $window = $(window),
    $html = $('html'),
    $body = $('body'),
    $viewportHeight = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0)
    ;


/*
|
| Barba Hook 
|-----------
*/

/*
|
| before Leave
|-------------
*/
barba.hooks.beforeLeave((data) => {

    /*
    |
    | Remove menu open
    |-----------------
    */
    $('body').removeClass('is-menu')
    $('.c-scrollbar').addClass('is-off')
})

/*
|
| after Leave
|-------------
*/
barba.hooks.afterLeave((data) => {

    /*
    |
    | Begin on top
    |-------------
    */
    if ($html.hasClass('is-device')) {
        window.scrollTo(0, 0)
    } else {
        window.scroll.scrollTo('top', {
            'offset': 0,
            'duration': 0,
            'easing': [0.25, 0.00, 0.35, 1.00],
            'disableLerp': true
        })
    }
})

/*
|
| before Enter
|-------------
*/
barba.hooks.beforeEnter((data) => {


    /*
    |
    | Switch Theme Color 
    |-------------------
    */
    const
        $themeElement = $('[data-theme]'),
        $themeColor = $(data.next.container).find('.theme-color'),
        $colorSpan = $(data.next.container).find('.theme-color span')
        ;

    if (sessionStorage.theme == "dark") {
        $colorSpan.text('Light')
    } else {
        $colorSpan.text('Dark')
    }

    $themeColor.on('click', function () {
        if (sessionStorage.theme != "dark") {
            $themeElement.attr('data-theme', 'dark')
            sessionStorage.theme = 'dark'
            $colorSpan.text('Light')
        } else {
            $themeElement.attr('data-theme', 'light')
            sessionStorage.theme = "light"
            $colorSpan.text('Dark')
        }
    })


    /*
    |
    | ScrollTo
    |---------
    */
    $(data.next.container).find('.scrollto').on('click', function () {
        scroll.scrollTo($viewportHeight)
    })


    /*
    |
    | Header white/black
    |-------------------
    */
    const $hero = data.next.container.querySelector('.hero-white')
    if ($hero != null) {
        $('header').addClass('is-light')
    } else {
        $('header').removeClass('is-light')
    }
})

/*
|
| after Enter
|-------------
*/
barba.hooks.afterEnter((data) => {

    /*
    |
    | Update lazyload
    |
    */
    window.lazy.update()

    setTimeout(() => {

        /*
        |
        | Update locomotive 
        |
        */
        window.scroll.update()
        $('.c-scrollbar').removeClass('is-off')
      
      
      
                     $(".contour, .centre, .p1, .p2, .p3, .p4, .p5, .p6, .p7, .pt1, .pt2, .pt3, .pt4, .pt5, .pt6, .pt7").click(function(){
               $(this).toggleClass('clicked');
               });
          
               $("#modele1").click(function(){
               $(".contour, .centre, .p1, .p2, .p3, .p4, .p5, .p6, .p7, .pt1, .pt2, .pt3, .pt4, .pt5, .pt6, .pt7").removeClass('clicked');
               });
          
               $("#modele2").click(function(){
               $(".contour, .p1, .p2, .p3, .p4, .p5, .p6, .p7, .pt1, .pt2, .pt3, .pt4, .pt5, .pt6, .pt7").removeClass('clicked');
               $(".centre").addClass('clicked');
               });
          
               $("#modele3").click(function(){
               $(".contour, .centre, .p1, .p2, .p3, .p4, .p5, .p6, .p7").removeClass('clicked');
               $(".pt1, .pt2, .pt3, .pt4, .pt5, .pt6, .pt7").addClass('clicked');
               });
          
               $("#modele4").click(function(){
               $(".contour, .p1, .p2, .p3, .p4, .p5, .p6, .p7").removeClass('clicked');
               $(".centre, .pt1, .pt2, .pt3, .pt4, .pt5, .pt6, .pt7").addClass('clicked');
               });
          
               $("#modele5").click(function(){
               $(".pt1, .pt2, .pt3, .pt4, .pt5, .pt6, .pt7, .contour, .centre").removeClass('clicked');
               $(".p1, .p2, .p3, .p4, .p5, .p6, .p7").addClass('clicked');
               });
          
               $("#modele6").click(function(){
               $(".contour, .centre").removeClass('clicked');
               $(".p1, .p2, .p3, .p4, .p5, .p6, .p7, .pt1, .pt2, .pt3, .pt4, .pt5, .pt6, .pt7").addClass('clicked');
               });
          
                $("#modele7").click(function(){
                $(".contour").removeClass('clicked');
                $(".centre, .p1, .p2, .p3, .p4, .p5, .p6, .p7, .pt1, .pt2, .pt3, .pt4, .pt5, .pt6, .pt7").addClass('clicked');
                }); 

        /*
        |
        | Scroll to top
        |
        */
        $(data.next.container).find('.scrollup').on('click', function () {
            scroll.scrollTo('top')
        })

    }, 300)

})


/*
|
| Barba Routing 
|--------------
*/
barba.init({
    timeout: 15000,
    preventRunning: true,

    prevent: ({ el }) => el.classList && el.classList.contains('prevent'),

    transitions: [{
        name: 'default-transition',
        sync: false,
        leave(data) {
            gsap.to(data.current.container, {
                opacity: 0
            })
        },
        enter(data) {
            gsap.from(data.next.container, {
                opacity: 0
            })

            // Contact form 7 
            const form = data.next.container.querySelectorAll('.wpcf7 > form')
            form.forEach((el) => {
                wpcf7.initForm(el)
                if (wpcf7.cached) {
                    wpcf7.refill(el)
                }
            })
        }
    }],
    views: [
        Home(),
        About(),
        Contact(),
        News(),
        Article(),
        Projects()
    ]
})
